.az-candle-stick {
  &__chart {
    position: relative;
    height: 100%;
  }

  &__legend {
    display: flex;
    justify-content: space-around;
  }

  &__legend-item {
    display: flex;
  }

  &__colored-box {
    width: 12px;
    height: 12px;
    margin: auto 4px auto 0;
  }

  &__label {
    font-size: 10px;
    margin: auto 0;
    text-align: left;
  }
}
